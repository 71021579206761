/**
 * Created by dev on 02.06.17.
 */
if (argilla) {

  argilla.event = function() {
    return this;
  };

  argilla.event.list = [
    'load.page',
    'show.popup',
    'hide.popup',
    'click',
    'collection.add',
    'collection.remove',
    'submit',
    'response',
    'response.success',
    'purchase'
  ];

  argilla.event.raise = function(eventName, data) {
    if( argilla.event.list.indexOf(eventName) === -1 ) {
      argilla.message('Событие ' + escape(eventName) + ' не найдено');
      return;
    }

    argilla.event._raiseAfterLoadDOM(eventName, data);
  };

  argilla.event.attachEventHandler = function(eventName, callback) {
    document.addEventListener(eventName, function(event) {
      callback(event.detail);
    });
  };

  argilla.event.init = function() {
    argilla.event.attachEventHandler('load.page', function(data) {
      argilla.event._setDefaultParams(data);
      argilla.event._attachOnClickListener();
    });
  }();

  argilla.event._raiseAfterLoadDOM = function(eventName, data) {

    var eventFunction = function() {
      var event = new CustomEvent(eventName, {detail : data});
      document.dispatchEvent(event);

      argilla.message('Вызвано событие ' + escape(eventName));
      argilla.message(data);
    };

    if( argilla.getParam('domHasLoaded') )
      eventFunction();
    else {
      document.addEventListener('DOMContentLoaded', function() {
        eventFunction();
      });
    }
  };

  argilla.event._setDefaultParams = function(data) {
    argilla.setParam('domHasLoaded', true);
    argilla.setParam('route', data.route);
  };

  argilla.event._attachOnClickListener = function() {
    $('body').on('click', '[data-event=click]', function(e) {
      argilla.event.raise('click', $(this).data('event-name'));
    });
  };
}
