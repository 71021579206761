/**
 * Created by dev on 05.06.17.
 */
if (argilla) {

  argilla.dom = function() {
    return this;
  };

  argilla.dom.getElementByName = function(name) {
    return argilla.dom.getElementByNameAndValue(name);
  };

  argilla.dom.getElementByNameAndValue = function(name, value) {
    var normalizedName = name.replace('[', '\\[').replace(']', '\\]');
    if( value === undefined )
      return $('[name=' + normalizedName + '][type!=hidden]');
    return $('[name=' + normalizedName  + '][type!=hidden][value=' + value + ']');
  };

  argilla.dom.showYiiValidateErrors = function(form, messages)
  {
    var data = form.data();

    form.yiiactiveform.updateSummary(form, messages);

    $.each(data.settings.attributes, function ()
    {
      if( $('#' + this.errorID).length )
      {
        form.yiiactiveform.updateInput(this, messages, form)
        delete messages[this.id];
      }
    });

    var error = [];
    for(var i in messages)
      if(messages.hasOwnProperty(i))
        error.push(messages[i].join("\n"));

    if(error.length)
      alert(error.join("\n"));
  }

  argilla.dom.updateElements = function(data)
  {
    for(var i in data)
    {
      if( data.hasOwnProperty(i) )
      {
        selector = '.' + i + ',#' + i;

        if( $(selector).is('input') )
          $(selector).val(data[i]);
        else if( $(selector).hasClass('text') )
          $(selector).text(data[i]);
        else
          $(selector).replaceWith(data[i]);
      }
    }
  };

  argilla.dom.hideElements = function(data)
  {
    for(var i in data)
    {
      if( data.hasOwnProperty(i) )
      {
        selector = '.' + data[i] + ',#' + data[i];
        if( $(selector).length )
          $(selector).hide();
      }
    }
  };

  argilla.dom.showElements = function(data)
  {
    for(var i in data)
    {
      selector = '.' + data[i] + ',#' + data[i];

      if( data.hasOwnProperty(i) )
        if( $(selector).length )
          $(selector).show();
    }
  };

  argilla.dom.removeElements = function (data)
  {
    for(var i in data)
    {
      if( data.hasOwnProperty(i) )
        if( $('.' + data[i]).length )
          $('.' + data[i]).remove();
    }
  };
}
